/* styles/GeneralRegulations.css */
.general-regulations-container {
  font-family: 'Roboto', Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
}

.general-regulations-title {
  color: #1a2b3c;
  border-bottom: 3px solid #4a6079;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.introduction {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.regulations-section {
  background-color: #f5f5f5;
  border-left: 4px solid #4a6079;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0 5px 5px 0;
}

.regulations-section h2 {
  color: #4a6079;
  border-bottom: 2px solid #4a6079;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.formation-tracks {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.frontend-track,
.backend-track {
  flex: 1;
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.frontend-track h3,
.backend-track h3 {
  color: #4a6079;
  border-bottom: 1px solid #4a6079;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.regulations-section ul,
.regulations-section ol {
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.regulations-section li {
  margin-bottom: 0.5rem;
}

.regulations-section strong {
  color: #2c3e50;
}

@media (max-width: 768px) {
  .formation-tracks {
    flex-direction: column;
  }

  .general-regulations-container {
    padding: 1rem;
  }
}