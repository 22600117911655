/* styles/Regulations.css */
.regulations-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background: #87c0d8;
  box-shadow: 0 4px 6px #04f8d4;
}

.regulations-title {
  text-align: center;
  color: #1a1a2e;
  border-bottom: 3px solid #4a4e69;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.regulations-section {
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(159, 161, 163, 0.1);
  border-left: 4px solid #4a4e69;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0 5px 5px 0;
}

.regulations-section h2 {
  color: #4a4e69;
  border-bottom: 2px solid #4a4e69;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.regulations-section:hover {
  transform: translateY(-2px);
  transform: scale(0.98);
}

.regulations-section ul,
.regulations-section ol {
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.regulations-section li {
  margin-bottom: 0.5rem;
}

.regulations-section strong {
  color: #2c3e50;
}

.regulations-section em {
  color: #4a4e69;
  font-style: italic;
}

.final-message {
  text-align: center;
  font-weight: bold;
  color: #4a4e69;
  margin-top: 2rem;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 600px) {
  .regulations-container {
    padding: 1rem;
  }

  .regulations-section {
    padding: 1rem;
  }
}