/* Reset and base styles */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
  background-color: rgb(220, 223, 223);
  color: white;
}

/* Common styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.text-center {
  text-align: center;
}

/* Button styles */
.button {
  background-color: #3b82f6;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #2563eb;
}
/* Règles générales */
.regulations-container {
  font-family: "Roboto", Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.regulations-title {
  color: #1a2b3c;
  border-bottom: 3px solid #4a6079;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

.regulations-section {
  background-color: #f5f5f5;
  border-left: 4px solid #4a6079;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0 5px 5px 0;
}

.regulations-section h2 {
  color: #4a6079;
  border-bottom: 2px solid #4a6079;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.regulations-section ul,
.regulations-section ol {
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.regulations-section li {
  margin-bottom: 0.5rem;
}

.regulations-section strong {
  color: #2c3e50;
}

.regulations-section em {
  color: #4a6079;
  font-style: italic;
}

/* Responsive */
@media (max-width: 600px) {
  .regulations-container {
    padding: 1rem;
  }

  .regulations-section {
    padding: 1rem;
  }
}


a:hover{
  color: rgb(0, 174, 255);
}