.about-section {
  padding: 6rem 2rem;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about-header {
  text-align: center;
  margin-bottom: 4rem;
}

.about-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #3498db, #2ecc71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.about-subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.value-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.value-card:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.value-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(52, 152, 219, 0.1);
  border-radius: 50%;
  color: #3498db;
  transition: all 0.3s ease;
}

.value-card:hover .value-icon {
  background: linear-gradient(45deg, #3498db, #2ecc71);
  color: white;
  transform: rotate(360deg);
}

.value-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
}

.value-card p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.about-cta {
  text-align: center;
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 2rem;
}

.about-cta h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: white;
}

.about-cta p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
}

.cta-button {
  background: linear-gradient(45deg, #3498db, #2ecc71);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.4);
}

@media (max-width: 768px) {
  .about-section {
    padding: 4rem 1rem;
  }

  .about-header h2 {
    font-size: 2rem;
  }

  .values-grid {
    grid-template-columns: 1fr;
  }

  .about-cta {
    padding: 2rem 1rem;
  }
}

/* Animations */
.value-card {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
}

.value-card:nth-child(2) {
  animation-delay: 0.2s;
}

.value-card:nth-child(3) {
  animation-delay: 0.4s;
}

.about-cta {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: 0.6s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
